<template>
  <div class="pd20">

    <div class="flex space alcenter">
      <div class="ft20 cl-main ftw600">答题详情</div>
      <a-button @click="$router.go(-1)">返回</a-button>
    </div>

    <div class="mt20">
      <div class="pd20 bg-w">
        <div class="mt20">
          <a-form layout="inline">
            <a-form-item label="">
              <a-input v-model="search.name" placeholder="请输入答题者"></a-input>
            </a-form-item>

            <a-form-item>
              <a-button @click="searchAct" type="primary">查询</a-button>
              <a-button @click="cancelAct" class="ml10">取消</a-button>
            </a-form-item>

          </a-form>
        </div>

        <div class="mt20">
          <div class="wxb-table-gray">
            <a-table rowKey="id"
                     :columns="columns"
                     :pagination="pagination"
                     @change="handleTableChange"
                     :data-source="datas"
                     :scroll='{x:true}'
                     :loading="loading"></a-table>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {listMixin} from '@/common/mixin/list';
export default{
  mixins: [listMixin],
  components:{},
  data(){
    return{
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
        showTotal:false,
      },
      datalits:[],
      form_id: 0,
      search:{
        name:'',
      },
      columns: [],
      datas: [],
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.form_id = 0;
    } else {
      this.form_id = parseInt(this.$route.query.id);
    }
  },
  methods:{
    getLists(){

      if (!this.$route.query.id) {
        this.form_id = 0;
      } else {
        this.form_id = parseInt(this.$route.query.id);
      }

      if(this.loading===true) return;
      this.loading=true;
      this.$http.api('admin/getAnswerList',{
        limit:this.pagination.pageSize,
        page:this.pagination.current,
        name:this.search.name,
        form_id:this.form_id,
      }).then(res=>{
        this.pagination.total=res.total;
        this.datas=res.list;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        if(this.columns.length==0){
          this.columns=res.columns;
        }
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    },
  }
}
</script>

<style>
.projetc-index-cover-img{
  width: 80px;
  height: 60px;
}
.project-type-icon img{
  width: 60px;
  height: 60px;
}


</style>
